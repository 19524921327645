import './google-maps-api.ts';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('google-map-marker')
export class GoogleMapMarker extends LitElement {
  @property({ type: Number, reflect: true })
  latitude = 0;

  @property({ type: Number, reflect: true })
  longitude = 0;

  @property({ type: String, reflect: true })
  label: string | null = null;

  @property({ type: Number, reflect: true, attribute: 'z-index' })
  zIndex = 0;

  @property({ type: Boolean, reflect: true })
  open = false;

  @property({ type: String, reflect: true })
  icon: string | null = null;

  map: google.maps.Map | null = null;
  marker: google.maps.Marker | null = null;
  info?: google.maps.InfoWindow;
  contentObserver?: MutationObserver;
  openInfoHandler?: google.maps.MapsEventListener;
  closeInfoHandler?: google.maps.MapsEventListener;

  attributeChangedCallback(name: string, oldVal: any, newVal: any) {
    super.attributeChangedCallback(name, oldVal, newVal);
    switch (name) {
      case 'open': {
        this.openChanged();
        break;
      }
      case 'latitude': {
        this.updatePosition();
        break;
      }
      case 'longitude': {
        this.updatePosition();
        break;
      }
      case 'label': {
        this.marker?.setLabel(newVal);
        break;
      }
      case 'z-index': {
        this.marker?.setZIndex(newVal);
        break;
      }
    }
  }

  openChanged() {
    if (!this.info) return;

    if (this.open) {
      this.info.open(this.map, this.marker);
      this.dispatchEvent(new CustomEvent('google-map-marker-open', { bubbles: true }));
    } else {
      this.info.close();
      this.dispatchEvent(new CustomEvent('google-map-marker-close', { bubbles: true }));
    }
  }

  updatePosition() {
    this.marker?.setPosition(new google.maps.LatLng(this.latitude, this.longitude));
  }

  changeMap(newMap: google.maps.Map) {
    this.map = newMap;
    this.mapChanged();
  }

  mapChanged() {
    // Marker will be rebuilt, so disconnect existing one from old map and listeners.
    if (this.marker) {
      this.marker.setMap(null);
      google.maps.event.clearInstanceListeners(this.marker);
    }

    if (this.map && this.map instanceof google.maps.Map) {
      this.mapReady();
    }
  }

  mapReady() {
    this.marker = new google.maps.Marker({
      map: this.map,
      icon: this.icon,
      position: {
        lat: this.latitude,
        lng: this.longitude
      },
      label: this.label,
      zIndex: this.zIndex
    });

    this.contentChanged();
  }

  contentChanged() {
    if (this.contentObserver) this.contentObserver.disconnect();

    this.contentObserver = new MutationObserver(this.contentChanged.bind(this));
    this.contentObserver.observe(this, {
      childList: true,
      subtree: true
    });

    const content = this.innerHTML.trim();
    if (content) {
      if (!this.info) {
        this.info = new google.maps.InfoWindow();

        this.openInfoHandler = google.maps.event.addListener(this.marker!, 'click', () => {
          this.open = true;
        });

        this.closeInfoHandler = google.maps.event.addListener(this.info, 'closeclick', () => {
          this.open = false;
        });
      }
      this.info.setContent(content);
    } else {
      if (this.info) {
        // Destroy the existing info window.  It doesn't make sense to have an empty one.
        google.maps.event.removeListener(this.openInfoHandler!);
        google.maps.event.removeListener(this.closeInfoHandler!);
        this.info = undefined;
      }
    }
  }
}

export class MapSelection<TItem> {
  multi: boolean;
  selection: Array<TItem>;
  selectCallback?: (item: TItem, isSelected: boolean) => void;

  constructor(selectCallback?: (item: TItem, isSelected: boolean) => void) {
    this.multi = false;
    this.selection = [];
    this.selectCallback = selectCallback;
  }

  get(): Array<TItem> | TItem {
    return this.multi ? this.selection.slice() : this.selection[0];
  }

  clear(excludes?: Array<TItem>) {
    this.selection.slice().forEach(item => {
      if (!excludes || excludes.indexOf(item) < 0) this.setItemSelected(item, false);
    });
  }

  isSelected(item: TItem): boolean {
    return this.selection.indexOf(item) >= 0;
  }

  setItemSelected(item: TItem, isSelected: boolean) {
    if (item == null || isSelected == this.isSelected(item)) return;

    if (isSelected) {
      this.selection.push(item);
    } else {
      const i = this.selection.indexOf(item);
      if (i >= 0) {
        this.selection.splice(i, 1);
      }
    }

    if (this.selectCallback) {
      this.selectCallback(item, isSelected);
    }
  }

  select(item: TItem) {
    if (this.multi) {
      this.toggle(item);
    } else if (this.get() !== item) {
      this.setItemSelected(this.get() as TItem, false);
      this.setItemSelected(item, true);
    }
  }

  toggle(item: TItem) {
    this.setItemSelected(item, !this.isSelected(item));
  }
}

@customElement('map-selector')
export class MapSelector extends LitElement {
  @property({ type: String, attribute: 'activate-event' })
  activateEvent = 'tap';

  @property({ type: String, attribute: 'selected-attribute' })
  selectedAttribute: string | null = null;

  @property({ type: Number, reflect: true })
  selected: number | string | null = null;

  _selection: MapSelection<Node> = new MapSelection((item, isSelected) => this.applySelection(item, isSelected));

  _items: Array<Node> = [];

  get items(): Array<Node> {
    return this._items;
  }

  connectedCallback() {
    super.connectedCallback();

    // eslint-disable-next-line wc/require-listener-teardown
    this.addEventListener('slotchange', event => {
      event.stopPropagation();
      this.updateItems();
      this.dispatchEvent(new CustomEvent('selector-items-changed', { detail: {}, composed: true }));
    });

    this.addListener(this.activateEvent);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeListener(this.activateEvent);
  }

  attributeChangedCallback(name: string, oldval: any, newval: any) {
    super.attributeChangedCallback(name, oldval, newval);
    switch (name) {
      case 'selected': {
        this.updateSelected();
        break;
      }
    }
  }

  applySelection(item: Node, isSelected: boolean) {
    if (this.selectedAttribute && item instanceof Element) {
      if (isSelected != (item as Element).hasAttribute(this.selectedAttribute))
        (item as Element).toggleAttribute(this.selectedAttribute);
    }
  }

  updateItems() {
    const slotElement = this.querySelector('slot');
    this._items = slotElement?.assignedNodes() ?? [];
  }

  addListener(eventName: string) {
    this.addEventListener(eventName, event => this.activateHandler(event));
  }

  removeListener(eventName: string) {
    this.removeEventListener(eventName, event => this.activateHandler(event));
  }

  activateHandler(event: Event) {
    let t = event.target as Node;
    const items = this.items;
    while (t && t != this) {
      const i = items.indexOf(t);
      if (i >= 0) {
        const value = this.indexToValue(i);
        this.itemActivate(value, t);
        return;
      }
      t = t.parentNode!;
    }
  }

  itemActivate(value: any, item: any) {
    if (
      this.dispatchEvent(
        new CustomEvent('selector-item-activate', {
          detail: { selected: value, item: item },
          composed: true,
          cancelable: true
        })
      )
    )
      this.select(value);
  }

  select(value: any) {
    this.selected = value;
  }

  updateSelected() {
    this.selectSelected(this.selected);
  }

  selectSelected(_selected: number | string | null) {
    if (!this._items) return;

    const item = this.valueToItem(this.selected);
    if (item) {
      this._selection.select(item);
    } else {
      this._selection.clear();
    }
  }

  valueToItem(value: number | string | null): Node | null {
    return value == null ? null : this._items[this.valueToIndex(value)];
  }

  valueToIndex(value: number | string): number {
    return Number(value);
  }

  indexToValue(index: number): any {
    return index;
  }

  indexOf(item: Node): number {
    return this._items ? this._items.indexOf(item) : -1;
  }
}

@customElement('google-map')
export class GoogleMap extends LitElement {
  static styles = css`
    #map {
      width: 100%;
      height: 100%;
    }
  `;
  /**
   * A Maps API key. To obtain an API key, see https://developers.google.com/maps/documentation/javascript/tutorial#api_key.
   */
  @property({ type: String, attribute: 'api-key' })
  apiKey = '';

  /**
   * Version of the Google Maps API to use.
   */
  @property({ type: String })
  version = '3.59';

  /**
   * If set, custom styles can be applied to the map.
   * For style documentation see https://developers.google.com/maps/documentation/javascript/reference#MapTypeStyle
   */
  @property({ type: Object })
  styles: object = {};
  /**
   * A zoom level to set the map to.
   */
  @property({ type: Number, reflect: true })
  zoom = 8;
  /**
   * If set, the zoom level is set such that all markers (google-map-marker children) are brought into view.
   */
  @property({ type: Boolean, attribute: 'fit-to-markers' })
  fitToMarkers = false;
  /**
   * Map type to display. One of 'roadmap', 'satellite', 'hybrid', 'terrain'.
   */
  @property({ type: String, attribute: 'map-type', reflect: true })
  mapType = 'roadmap';
  @property({ type: Number, attribute: 'center-latitude', reflect: true })
  centerLatitude = -34.397;
  @property({ type: Number, attribute: 'center-longitude', reflect: true })
  centerLongitude = 150.644;
  @property({ type: String })
  language = '';
  @property({ type: String, attribute: 'map-id' })
  mapId = '';
  map: google.maps.Map | null = null;
  markers?: Array<Node>;
  markerObserverSet?: boolean;

  initGMap() {
    if (this.map != null) {
      return; // already initialized
    }

    const gMapApiElement = this.shadowRoot!.getElementById('api') as any;

    if (gMapApiElement == null || gMapApiElement.libraryLoaded != true) {
      return;
    }

    this.map = new google.maps.Map(this.shadowRoot!.getElementById('map')!, this.getMapOptions());

    this.updateMarkers();
  }

  getMapOptions(): google.maps.MapOptions {
    return {
      zoom: this.zoom,
      center: { lat: this.centerLatitude, lng: this.centerLongitude },
      mapTypeId: this.mapType,
      mapId: this.mapId
    };
  }

  attributeChangedCallback(name: string, oldVal: any, newVal: any) {
    super.attributeChangedCallback(name, oldVal, newVal);
    switch (name) {
      case 'center-latitude':
      case 'center-longitude':
      case 'zoom':
      case 'map-type':
      case '': {
        if (this.fitToMarkers) {
          this.fitToMarkersChanged();
        }
        break;
      }
    }
  }

  mapApiLoaded() {
    this.initGMap();
  }

  connectedCallback() {
    super.connectedCallback();
    this.initGMap();
  }

  attachChildrenToMap(children: Array<Node>) {
    if (this.map) {
      for (const child of children) {
        (child as GoogleMapMarker).changeMap(this.map);
      }
    }
  }

  observeMarkers() {
    if (this.markerObserverSet) return;

    this.addEventListener('selector-items-changed', _event => {
      this.updateMarkers();
    });
    this.markerObserverSet = true;
  }

  updateMarkers() {
    this.observeMarkers();

    const markersSelector = this.shadowRoot?.getElementById('markers-selector') as MapSelector;
    if (!markersSelector) return;

    const newMarkers = markersSelector.items;

    // do not recompute if markers have not been added or removed
    if (this.markers && newMarkers.length === this.markers.length) {
      const added = newMarkers.filter(m => {
        return this.markers && this.markers.indexOf(m) === -1;
      });
      if (added.length == 0) return;
    }

    this.markers = newMarkers;

    this.attachChildrenToMap(this.markers);

    if (this.fitToMarkers) {
      this.fitToMarkersChanged();
    }
  }

  fitToMarkersChanged() {
    if (this.map && this.fitToMarkers && this.markers && this.markers.length > 0) {
      const latLngBounds = new google.maps.LatLngBounds();
      for (const marker of this.markers) {
        latLngBounds.extend(
          new google.maps.LatLng((marker as GoogleMapMarker).latitude, (marker as GoogleMapMarker).longitude)
        );
      }

      // For one marker, don't alter zoom, just center it.
      if (this.markers.length > 1) {
        this.map.fitBounds(latLngBounds);
      }

      this.map.setCenter(latLngBounds.getCenter());
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deselectMarker(_event: Event) {}

  render() {
    return html`
      <google-maps-api
        id="api"
        api-key="${this.apiKey}"
        version="${this.version}"
        language="${this.language}"
        map-id="${this.mapId}"
        @api-load=${() => this.mapApiLoaded()}
      >
      </google-maps-api>
      <map-selector
        id="markers-selector"
        selected-attribute="open"
        activate-event="google-map-marker-open"
        @google-map-marker-close=${e => this.deselectMarker(e)}
      >
        <slot id="markers" name="markers"></slot>
      </map-selector>
      <div id="map"></div>
    `;
  }
}
